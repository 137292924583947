<template src="./managefee.html" />
<style scoped src="./managefee.css" />
<script>
import fxService from '@/services/FXServices.vue'
import Loading from 'vue-loading-overlay'
import modal from '@/components/modal/notification/modal'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import AccConstants from '@/constants/AccountConstants.vue'
import AppConstants from '@/constants/AppConstants.vue'
import transactionConstants from '@/constants/TransactionConstants.vue'

export default {
    name:'managefees',
    components: {
        Loading, modal
    },
    data() {
        return {
            loaderVisible: false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            transactionSourceType: null,
            transactionSource: null,
            transactionDestinationType: null,
            transactionDestination: null,
            transactionType: null,
            flatFee: null,
            feePercentage: null,
            feesData: null,
            columns: ['FeeFlat', 'FeePercentage', 'Id', 'TransactionDestinationName', 'TransactionDestinationTypeName','TransactionTypeName', 'Action'],
            options: {
                headings: {
                    TransactionDestinationName: "TransactionDestination",
                    TransactionDestinationTypeName: "TransactionDestinationType",
                    TransactionTypeName: "TransactionType"
                }
            },
            enableLazyLoading: AppConstants.enableLazyLoading,
            transactionSourceTypeList: AccConstants.transactionSourceTypes,
            transactionSourceList: AccConstants.fundingSources,
            transactionDestinationTypeList: AccConstants.transactionDestinationTypes,
            remittanceGatewaysList: AccConstants.remittanceGatewayId,
            actionButtonType: 'Add',
            clearButtonType: 'clear',
            operationType: 'A',
            selectedFeeId: null,
            transactionTypeList: transactionConstants.transactionTypes
        }
    },
    validations: {
        transactionDestinationType: { required },
        transactionDestination: { required },
        transactionType: {required },
        flatFee: { required },
        feePercentage: { required },
        feesData: { required }
    },
    methods: {
        ...mapActions(['fetchFees']),
        editFees(fees) {
            this.actionButtonType = 'Update'
            this.clearButtonType = 'Cancel'
            this.operationType = 'U'
            this.selectedFeeId = fees.Id
            this.transactionSourceType = fees.TransactionSourceType
            this.transactionSource = fees.TransactionSource
            this.transactionDestination = fees.TransactionDestination
            this.transactionDestinationType = fees.TransactionDestinationType
            this.flatFee = fees.FeeFlat
            this.feePercentage = fees.FeePercentage
            this.transactionType = fees.TransactionType
            scrollTo(0,0)
        },
        manageFees() {
            this.$v.$touch()
            if(this.$v.$invalid) {
                return
            }
            
            this.loaderVisible = true
            fxService.methods.ManageFees({
                "FeeDetail":{
                    "FeeFlat": this.flatFee,
                    "FeePercentage": this.feePercentage,
                    "Id": this.selectedFeeId,
                    "TransactionDestination":this.transactionDestination,
                    "TransactionDestinationType": this.transactionDestinationType,
                    "TransactionSource": this.transactionSource,
                    "TransactionSourceType": this.transactionSourceType,
                    "TransactionType": this.transactionType
                },
                "OperationType": this.operationType
            })
            .then(async res => {
                let msg = this.aperationType == 'A' ? 'Added Successfully' : 'Updated Successfully'
                this.clearAll()
                this.loaderVisible = false
                this.showAlert(1, msg)
                await this.fetchFees()
            })
            .catch(err =>{
                this.loaderVisible = false
                this.alertObject =err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        checkFeePercentage() {
            this.feePercentage = this.feePercentage > 100 || this.feePercentage < 0 ?  0 : +event.target.value
        },
        clearAll() {
            this.transactionSourceType = null
            this.transactionSource = null
            this.transactionDestination = null
            this.transactionDestinationType = null
            this.flatFee = null
            this.feePercentage = null
            this.actionButtonType = 'Add'
            this.clearButtonType = 'Clear'
            this.operationType = 'A'
            this.selectedFeeId = null
            this.transactionType = null
            this.$v.$reset()
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
    computed: {
        ...mapGetters(['getFees']),
        feeTableData() {
            let feeTableData = []
            if(this.getFees) {
                feeTableData = this.getFees
                if(this.enableLazyLoading) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    feeTableData = feeTableData.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.getFees?.length ?? 0
                }
            }
            this.enableLazyLoading ? updateTotalRecords() : null
            return feeTableData
        }
    },
    async mounted () {
        this.loaderVisible = true
        if (this.getFees && this.getFees.length == 0) {
            await this.fetchFees()
        } 
        this.feesData = await this.getFees
        this.loaderVisible = false
    }
}
</script>