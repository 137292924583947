import { render, staticRenderFns } from "./managefee.html?vue&type=template&id=27591e52&scoped=true&"
import script from "./managefee.vue?vue&type=script&lang=js&"
export * from "./managefee.vue?vue&type=script&lang=js&"
import style0 from "./managefee.css?vue&type=style&index=0&id=27591e52&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27591e52",
  null
  
)

export default component.exports